/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, MouseEvent, useState } from 'react';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { styled } from '@mui/material/styles';
import {
  IconButton,
  Box,
  Typography,
  Grid,
  TextField,
  Tooltip,
} from "@mui/material";
import _ from 'lodash';
import CssContainer from 'components/CssContainer';
import CssTitle from 'components/CssTitle';
import Transactions from 'components/Transactions';
import Transfers from 'components/Transfers';
import Referrals from 'components/Referrals';
import Download from 'components/icons/Download';
import CssFlexBox from 'components/CssFlexBox';
import ButtonGroup from 'components/ButtonGroup';
import { GlobalContext } from 'context';
import { checkPermissions } from 'utils';
import useRequests from 'hooks/request-hook';
import useApi from 'hooks/api-hook';
import CssSnackbar from 'components/CssSnackbar';
import CssDialog from 'components/CssDialog';
import CssButton from 'components/CssButton';
import useStorage from 'hooks/storage-hook';
import DisabledPlus from 'components/icons/DisabledPlus';
import BankTransferPlus from 'components/icons/BankTransferPlus';
import ConfirmDialog from 'components/ConfirmDialog';
import ViewField from 'components/ViewField';

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  top: 36,
  width: 30,
  height: 30,
  border: '1px solid #00000042',
  borderRadius: 30,
  marginLeft: 40
}));

const StyledPlusIconButton = styled(IconButton)(({ theme }) => ({
  top: 36,
  width: 100,
  height: 100,
  borderRadius: 30,
  marginLeft: 40,
}));

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  margin: 32,
  marginTop: 60,
  marginBottom: 0,
  borderBottom: `1px solid ${theme.palette.primary.main}`,
  paddingBottom: 14
}));

const TransactionsList = (props :any) => {
  const {
    transactionsDownloadData,
    setTransactionsDownloadData,
    transactionsDownloadParams,
    setTransactionsDownloadParams,
    userPermissions,
    selectedButtonType,
    setSelectedButtonType,
    setTransactionFilterQuery,
    setDateRange,
    setSearchQuery,
    setIsSearchClosed,
    setFilterDates,
    setPaymentFilterDates,
    setIsFilterApplied,
    setIsFilterCleared,
    setEnableSearchField,
    setTransactionsStatusFilters,
    setGiftsStatusFilters,
    setIndeterminateCheck,
    setReportBatchIds,
    setIsReportBatchIdDeleted,
    setIsReportFilterApplied,
    setCheckboxList,
    setSelectedAllChecks,
    setDefaultFilterDates,
    setIsChangeReportBatchIds,
    setIsWithDrawalTabSelected,
    setDateSelectedFilter,
    setIsFilterIconDisplay,
    setEnableSelectAllCheckbox,
    setUncheckedTxnIds,
    setCheckedTxnIds,
    setFifoReportBatchId,
    setIsSelectAllCheckboxClicked,
    setMostAppearedTransactionStatus,
    setCheckedTransactionStatus,
    setClientFilterQuery,
    setAutocompleteIds,
    setIsAutocompleteIdDeleted,
    setIsAutocompleteFilterApplied,
    setIsChangeAutocompleteIds,
    setTransfersStatusFilters,
    setIsUpdatePage,
  } = useContext(GlobalContext);
  const { postActivityLogApi, postTransactionStatusApi } = useRequests();
  const postActivityLog = useApi(postActivityLogApi);
  const postTransactionUpdate = useApi(postTransactionStatusApi);
  const [isButtonHoverEnabled, setIsButtonHoverEnabled] = useState<boolean>(false);
  const [openTransferStatusScreen, setOpenTransferStatusScreen] = useState<boolean>(false);
  const [usdAmount, setUsdAmount] = useState<string>();
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<string>('');
  const [clientId, setClientId] = useStorage<number | null>('clientId', null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [confirmMessage, setConfirmMessage] = useState<string>("");

  const downloadDepositFileHeaders = [
    { label: 'Beneficiary Account Number', key: 'beneficiaryAccountNumber' },
    { label: 'Beneficiary Name', key: 'beneficiaryName' },
    { label: 'Depositor Name', key: 'clientName' },
    { label: 'Client ID', key: 'clientId' },
    { label: 'Remitter Account Number', key: 'remitterAccountNumber' },
    { label: 'Transaction No', key: 'txnNumber' },
    { label: 'Transaction Type', key: 'type' },
    { label: 'Local Currency', key: 'currency' },
    { label: 'Local Currency Amount', key: 'amount' },
    { label: 'Amount In $', key: 'amountInUSD' },
    { label: 'Exchange Rate', key: 'exchangeRate' },
    { label: 'Bank Transaction Reference', key: 'bankTransactionReference' },
    { label: 'Status', key: 'status' },
    { label: 'Last Status Updated At', key: 'txnStatusLastUpdatedAt' },
    { label: 'Transaction Timestamp', key: 'txnCreatedAt' },
    { label: 'Payment Provider', key: 'paymentProviderName' },
    { label: 'Payment Method', key: 'paymentMethod' }
  ];

  const downloadWithdrawalFileHeaders = [
    { label: 'Beneficiary Account Number', key: 'beneficiaryAccountNumber' },
    { label: 'SWIFT Code', key: 'swiftCode' },
    { label: 'Beneficiary Name', key: 'beneficiaryName' },
    { label: 'Client Name', key: 'clientName' },
    { label: 'Client ID', key: 'clientId' },
    { label: 'Remitter Account Number', key: 'remitterAccountNumber' },
    { label: 'Transaction No', key: 'txnNumber' },
    { label: 'Transaction Type', key: 'type' },
    { label: 'Local Currency', key: 'currency' },
    { label: 'Local Currency Amount', key: 'amount' },
    { label: 'Amount In $', key: 'amountInUSD' },
    { label: 'Exchange Rate', key: 'exchangeRate' },
    { label: 'Status', key: 'status' },
    { label: 'Last Status Updated At', key: 'txnStatusLastUpdatedAt' },
    { label: 'Transaction Timestamp', key: 'txnCreatedAt' },
    { label: 'Payment Method', key: 'paymentMethod' }
  ];

  const downloadTransferFileHeaders = [
    { label: 'Transfer ID', key: 'transferId' },
    { label: 'Date of Transfer', key: 'transactionDate' },
    { label: 'Status Updated Time', key: 'txnStatusLastUpdatedAt' },
    { label: 'Source', key: 'source' },
    { label: 'Destination', key: 'destination' },
    { label: 'USD Amount', key: 'amount' },
    { label: 'Client ID', key: 'clientId' },
    { label: 'Client Name', key: 'clientName' },
    { label: 'Transfer Status', key: 'transferStatus' }
  ];

  const downloadGiftsFileHeaders = [
    { label: 'Impact Reward ID', key: 'impactRewardId' },
    { label: 'Txn Number', key: 'txnNumber' },
    { label: 'Created At', key: 'createdAt' },
    { label: 'Status Updated Time', key: 'txnStatusLastUpdatedAt' },
    { label: 'Status', key: 'status' },
    { label: 'Reason', key: 'rewardType' },
    { label: 'USD Amount', key: 'amountInUSD' },
    { label: 'Client ID', key: 'customerId' },
    { label: 'Client Name', key: 'name' },
    { label: 'Total Approved Gifts', key: 'totalApprovedGifts' },
    { label: 'Max Approved Gifts', key: 'maxApproveGifts' }
  ];

  const downloadFilename = `payments-${moment().format('MM-DD-yyyy')}`;

  const checkTransactionPermissions = () => {
    return checkPermissions(userPermissions, 'PAYMENTS');
  };
  
  const triggerActivityLog = () => {
    let payload = {
      page: 'PAYMENTS',
      action: 'DOWNLOAD'
    };
    if (transactionsDownloadParams) {
      payload = {...payload, ...transactionsDownloadParams};
    }
    postActivityLog.request(_.omitBy(payload, _.isEmpty));
  };

  const onPaymentsType = (e: MouseEvent<HTMLDivElement>) => {
    const type = (e.target as HTMLDivElement).dataset.filter;
    setSelectedButtonType(type);
    setTransactionsDownloadData(null);
    setTransactionsDownloadParams(null);
    setTransactionFilterQuery('');
    setDateRange('');
    setSearchQuery('');
    setIsSearchClosed(true);
    setFilterDates({ fromDate: '', toDate: ''});
    setPaymentFilterDates({ fromDate: '', toDate: '', statusUpdatedFromDate: '',
    statusUpdatedToDate: '' });
    setIsFilterApplied(false);
    setIsFilterCleared(false);
    setEnableSearchField(false);
    setTransactionsStatusFilters((csf: any) => _.map(csf, (c) => {
      c.filters = _.map(c.filters, (f: any) => {
        if (f.isChecked) {
          f.isChecked = false;
        }
        return f;
      });
      return c;
    }));
    setGiftsStatusFilters((csf: any) => _.map(csf, (c) => {
      c.filters = _.map(c.filters, (f: any) => {
        if (f.isChecked) {
          f.isChecked = false;
        }
        return f;
      });
      return c;
    }));
    setIndeterminateCheck(false);
    setReportBatchIds([]);
    setIsReportBatchIdDeleted(false);
    setIsReportFilterApplied(false);
    setCheckboxList(null);
    setSelectedAllChecks(false);
    setDefaultFilterDates({ fromDate: '', toDate: ''});
    setIsChangeReportBatchIds(false);
    setIsWithDrawalTabSelected(false);
    setDateSelectedFilter('all');
    setIsFilterIconDisplay(false);
    setEnableSelectAllCheckbox(false);
    setUncheckedTxnIds([]);
    setCheckedTxnIds([]);
    setFifoReportBatchId('');
    setIsSelectAllCheckboxClicked(false);
    setMostAppearedTransactionStatus('');
    setCheckedTransactionStatus('');
    setClientFilterQuery('');
    setAutocompleteIds([]);
    setIsAutocompleteIdDeleted(false);
    setIsAutocompleteFilterApplied(false);
    setIsChangeAutocompleteIds(false);
    setTransfersStatusFilters((csf: any) => _.map(csf, (c) => {
      c.filters = _.map(c.filters, (f: any) => {
        f.isChecked = false;
        if (c.parentKey === 'status') {
          f.isDisabled = true;
        } else {
          f.isDisabled = false;
        }
        return f;
      });
      return c;
    }));
  };

  const getTransactionsView = () => {
    if (selectedButtonType === 'TRANSFERS_MAIN' ) {
      return <Transfers from={'TRANSFERS_MAIN'} />;
    } else if (selectedButtonType === 'REFERRAL_GIFTS') {
      return <Referrals from={'REFERRAL_GIFTS'} />
    } else {
      return <Transactions from={'TRANSACTIONS'} />;
    }
  };

  const getFileHeaders = () => {
    if (selectedButtonType === 'TRANSFERS_MAIN') {
      return downloadTransferFileHeaders;
    } else if (selectedButtonType === 'DEPOSIT') {
      return downloadDepositFileHeaders;
    } else if (selectedButtonType === 'REFERRAL_GIFTS') {
      return downloadGiftsFileHeaders;
    } else {
      return downloadWithdrawalFileHeaders;
    }
  };

  useEffect(() => {
    setSelectedButtonType('DEPOSIT');
  }, []);

  const iconClick = () => {
    setOpenTransferStatusScreen(true);
    setIsButtonHoverEnabled(true);
    setClientId(null);
    setUsdAmount("");
  }

  const toggleButton = (isToggle: boolean) => {
    setIsButtonHoverEnabled(isToggle);
  };

  const onSnackbarClose = () => {
    setOpenSnackbar(false);
    setSnackbarSeverity('');
    setSnackbarMessage('');
  };

  const onReconStatusScreenClose = (event: {},
    reason: 'backdropClick') => {
    setOpenTransferStatusScreen(false);
    setIsButtonHoverEnabled(false);
  };

  const onRowStatusUpdateScreenClose = () => {
    setOpenTransferStatusScreen(false);
    setUsdAmount("");
  }

  const onRowStatusUpdateConfirm = (isConfirmed: boolean) => {
    if (!isConfirmed) {
      let message =
        "Are you sure you want to reconcile this transaction? This will update the user wallet balance.";
      setOpenConfirmDialog(true);
      setConfirmMessage(message);
    } else {
      const payload = {
        type: "DEPOSIT",
        status: "RECONCILED",
        customerId: Number(clientId),
        amountInUSD: usdAmount ? parseFloat(usdAmount) : 0,
      };
      setOpenTransferStatusScreen(false);
      postTransactionUpdate
        .request(payload)
        .then((res: any) => {
          if (res.status === 200) {
            setIsUpdatePage(true);
            setOpenSnackbar(true);
            setSnackbarMessage(res.data.message);
            setClientId(null);
          }
        })
        .catch((err) => {
          setOpenSnackbar(true);
          setSnackbarSeverity("ERROR");
          setSnackbarMessage(err.response.data.error.message);
        });
    }
  };

  const onConfirmClose = () => {
    setOpenConfirmDialog(false);
  };

  const onConfirm = () => {
    onConfirmClose();
    onRowStatusUpdateConfirm(true);
  };

  return (
    <CssContainer>
      <CssFlexBox sx={{ justifyContent: "space-between" }}>
        <CssTitle variant="h4" underline>
          Payments
        </CssTitle>
        <Box display={"flex"}>
          <ButtonGroup
            data={[
              {
                label: "Deposits",
                type: "DEPOSIT",
                onClick: onPaymentsType,
              },
              {
                label: "Withdrawals",
                type: "WITHDRAW",
                onClick: onPaymentsType,
              },
              {
                label: "Transfers",
                type: "TRANSFERS_MAIN",
                onClick: onPaymentsType,
              },
              {
                label: "Gifts",
                type: "REFERRAL_GIFTS",
                onClick: onPaymentsType,
              },
            ]}
          />
          <CssSnackbar
            open={openSnackbar}
            message={snackbarMessage}
            severityType={snackbarSeverity}
            onClose={onSnackbarClose}
          />
          <CssDialog
            disableEscapeKeyDown={true}
            open={openTransferStatusScreen}
            onScreenClose={onReconStatusScreenClose}
          >
            <StyledBox sx={{ mt: 4.375, ml: 5.625, mr: 5.625 }}>
              <Typography variant="h6">Transaction</Typography>
            </StyledBox>
            <Box sx={{ pl: 5.625, pr: 5.625, pb: 5.25, pt: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    label="Client ID"
                    variant="standard"
                    fullWidth
                    value={clientId}
                    onChange={(e: any) => {
                      const value = e.target.value.replace(/\D/g, "");
                      setClientId(value);
                    }}
                    InputProps={{
                      inputProps: { pattern: "\\d*" },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="USD Amount"
                    variant="standard"
                    fullWidth
                    value={usdAmount}
                    onChange={(e: any) => {
                      const value = e.target.value;
                      if (/^\d*\.?\d{0,2}$/.test(value)) {
                        setUsdAmount(value);
                      }
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <ViewField
                    label={"Status"}
                    value={"Reconciled"}
                    labelVariant="body2"
                    valueSx={{
                      fontSize: "1rem",
                      fontStyle: "normal",
                    }}
                  />
                </Grid>
                <Grid container sx={{ pl: 1 }}>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      textAlign: "center",
                      mt: 4,
                      justifyContent: "space-between",
                      display: "flex",
                    }}
                  >
                    <CssButton
                      variant="outlined"
                      sx={{ width: "35%", mr: 5, ml: 0 }}
                      onClick={onRowStatusUpdateScreenClose}
                    >
                      Cancel
                    </CssButton>
                    <CssButton
                      disabled={!usdAmount && !clientId}
                      variant="contained"
                      sx={{ width: "35%", mr: 0, ml: 1 }}
                      onClick={() => onRowStatusUpdateConfirm(false)}
                      className="active"
                    >
                      Confirm
                    </CssButton>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </CssDialog>
          <ConfirmDialog
            open={openConfirmDialog}
            onClose={onConfirmClose}
            onConfirm={onConfirm}
            message={confirmMessage}
          />
          {selectedButtonType === "DEPOSIT" && (
            <Tooltip title="Create Self Bank Transfer Record">
              <StyledPlusIconButton
                sx={{ width: 23, height: 23, mt: 1, ml: 6 }}
                onClick={iconClick}
                onMouseEnter={() =>
                  !openTransferStatusScreen && toggleButton(true)
                }
                onMouseLeave={() =>
                  !openTransferStatusScreen && toggleButton(false)
                }
              >
                {isButtonHoverEnabled ? <BankTransferPlus /> : <DisabledPlus />}
              </StyledPlusIconButton>
            </Tooltip>
          )}
          {transactionsDownloadData && checkTransactionPermissions() ? (
            <CSVLink
              data={transactionsDownloadData || ""}
              headers={getFileHeaders()}
              filename={downloadFilename}
              style={{
                position: "relative",
                top: 36,
                width: 30,
                height: 30,
                border: "1px solid #000",
                borderRadius: 30,
                marginLeft: 22,
              }}
              onClick={triggerActivityLog}
            >
              <Download className={"download"} />
            </CSVLink>
          ) : (
            <StyledIconButton disabled>
              <Download fill={"#E5E5E5"} className={"download-disabled"} />
            </StyledIconButton>
          )}
        </Box>
      </CssFlexBox>
      {getTransactionsView()}
    </CssContainer>
  );
};

export default TransactionsList;