import Icon from './Icon';

const BankTransferPlus = ({ className, direction, fill }: any) => {
  return (
    <Icon className={className} direction={direction}>
      <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="15" cy="15" r="14.5" fill="#04F8DA" stroke="#04F8DA" />
        <path d="M15 21.25V8.75" stroke="white" stroke-width="1.5" stroke-linecap="round" />
        <path d="M8.75 15L21.25 15" stroke="white" stroke-width="1.5" stroke-linecap="round" />
      </svg>
    </Icon>
  );
};

export default BankTransferPlus;
