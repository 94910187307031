/* eslint-disable react-hooks/exhaustive-deps */
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import CssButton from 'components/CssButton';

interface ConfirmDialogProps {
  open: boolean;
  onClose: (event: {}, reason: "backdropClick") => void;
  onConfirm: (value: string, setValue: any) => void;
  title?: string;
  message: string;
}

const ConfirmDialog = (props: ConfirmDialogProps) => {
  const { open, message, title, onClose, onConfirm } = props;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent sx={{ padding: 2 }}>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions sx={{ padding: 2 }}>
        <CssButton variant="outlined" sx={{ mr: 1, ml: 0 }} onClick={onClose}>
          Cancel
        </CssButton>
        <CssButton
          variant="contained"
          sx={{ mr: 0, ml: 1 }}
          onClick={onConfirm}
        >
          Confirm
        </CssButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;