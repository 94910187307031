import React, { createContext, useState } from 'react';
import { ClientStatusFilters } from 'constants/client-table-status-filters';
import { TransactionsStatusFilters } from 'constants/transactions-table-status-filters';
import { FinopsTransactionsStatusFilters } from 'constants/finops-transactions-table-status-filters';
import { FinopsTransfersStatusFilters } from 'constants/finops-transfers-table-status-filters';
import { GiftsStatusFilters } from 'constants/gifts-table-status-filters';
import { InstrumentStatusFilters } from 'constants/instruments-table-status-filters';
import { TravelRuleReportsFilters } from 'constants/travel-rule-reports-table-status-filters';
import { SettlementReportsFilters } from 'constants/settlement-reports-table-status-filters';
import { OrdersFilters } from 'constants/orders-table-status-filters';
import { HolidayCalendarEditableFields } from 'constants/holiday-calendar-editable-row-columns';
import { TransactionHistoryFilters } from 'constants/transaction-history-table-status-filters';
import { InstrumentsProfileDetails } from 'constants/instruments-profile-details';
import { InstrumentsCfdProfileDetails } from 'constants/instruments-cfd-profile-details';
import { Permissions } from 'constants/permissions';
import { ClientProfileDetails } from 'constants/client-profile-details';
import { TransfersStatusFilters } from 'constants/transfers-table-status-filters';
import { ClientAccountDetails } from 'constants/client-account-details';

export const GlobalContext = createContext<any>(null);

type FilterDates = {
  fromDate: string,
  toDate: string
}

type PaymentsFilterDates = {
  fromDate: string,
  toDate: string,
  statusUpdatedFromDate: string,
  statusUpdatedToDate: string
}

const GlobalContextProvider = ({ children }: any) => {
  const [auth, setAuth] = useState<any>(null);
  const [userRole, setUserRole] = useState<string>('');
  const [userInfo, setUserInfo] = useState<any>(null);
  const [userPermissions, setUserPermissions] = useState<any[]>([]);
  const [dateRange, setDateRange] = useState<string>('');
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [isSearchClosed, setIsSearchClosed] = useState<boolean>(false);
  const [filterDates, setFilterDates] = useState<FilterDates>({ fromDate: '', toDate: ''});
  const [paymentFilterDates, setPaymentFilterDates] = useState<PaymentsFilterDates>({ fromDate: '', toDate: '', statusUpdatedFromDate: '', statusUpdatedToDate: '' });
  const [defaultFilterDates, setDefaultFilterDates] = useState<FilterDates>({ fromDate: '', toDate: ''});
  const [isFilterApplied, setIsFilterApplied] = useState<boolean>(false);
  const [isFilterCleared, setIsFilterCleared] = useState<boolean>(false);
  const [enableSearchField, setEnableSearchField] = useState(false);
  const [dashboardSelectedFilter, setDashboardSelectedFilter] = useState('DASHBOARD_COMP');
  const [clientStatusFilters, setClientStatusFilters] = useState<any>(ClientStatusFilters);
  const [transactionsStatusFilters, setTransactionsStatusFilters] = useState<any>(TransactionsStatusFilters);
  const [finopsTransactionsStatusFilters, setFinopsTransactionsStatusFilters] = useState<any>(FinopsTransactionsStatusFilters);
  const [finopsTransfersStatusFilters, setFinopsTransfersStatusFilters] = useState<any>(FinopsTransfersStatusFilters);
  const [giftsStatusFilters, setGiftsStatusFilters] = useState<any>(GiftsStatusFilters);
  const [legalEntity, setLegalEntity] = useState<any>(null);
  const [instrumentStatusFilters, setInstrumentStatusFilters] = useState<any>(InstrumentStatusFilters);
  const [isUpdatePage, setIsUpdatePage] = useState<boolean>(false);
  const [indeterminateCheck, setIndeterminateCheck] = useState<boolean>(false);
  const [travelRuleReportsFilters, setTravelRuleReportsFilters] = useState<any>(TravelRuleReportsFilters);
  const [settlementReportsFilters, setSettlementReportsFilters] = useState<any>(SettlementReportsFilters); 
  const [reportBatchIds, setReportBatchIds] = useState<any[]>([]);
  const [isReportBatchIdDeleted, setIsReportBatchIdDeleted] = useState<boolean>(false);
  const [isReportFilterApplied, setIsReportFilterApplied] = useState<boolean>(false);
  const [ordersFilters, setOrdersFilters] = useState<any>(OrdersFilters);
  const [isRowAdd, setIsRowAdd] = useState<boolean>(false);
  const [holidayListFields, setHolidayListFields] = useState<any>(HolidayCalendarEditableFields);
  const [checkboxList, setCheckboxList] = useState<any>(null);
  const [selectedAllChecks, setSelectedAllChecks] = useState<boolean>(false);
  const [isChangeReportBatchIds, setIsChangeReportBatchIds] = useState<boolean>(false);
  const [isWithDrawalTabSelected, setIsWithDrawalTabSelected] = useState<boolean>(true);
  const [isWalletTabSelected, setIsWalletTabSelected] = useState<boolean>(false);
  const [walletReportDownloadData, setWalletReportDownloadData] = useState<any>(null);
  const [transactionsDownloadData, setTransactionsDownloadData] = useState<any>(null);
  const [transactionsHistoryFilters, setTransactionsHistoryFilters] = useState<any>(TransactionHistoryFilters);
  const [isFilterIconDisplay, setIsFilterIconDisplay] = useState<any>(false);
  const [enableSelectAllCheckbox, setEnableSelectAllCheckbox] = useState<boolean>(false);
  const [uncheckedTxnIds, setUncheckedTxnIds] = useState<any[]>([]);
  const [checkedTxnIds, setCheckedTxnIds] = useState<any[]>([]);
  const [fifoReportBatchId, setFifoReportBatchId] = useState<string>('');
  const [instrumentsProfileDetails, setInstrumentsProfileDetails] = useState<any>(InstrumentsProfileDetails);
  const [instrumentsCfdProfileDetails, setInstrumentsCfdProfileDetails] = useState<any>(InstrumentsCfdProfileDetails);
  const [isSelectAllCheckboxClicked, setIsSelectAllCheckboxClicked] = useState<boolean>(false);
  const [mostAppearedTransactionStatus, setMostAppearedTransactionStatus] = useState<string>('');
  const [checkedTransactionStatus, setCheckedTransactionStatus] = useState<string>('');
  const [dateSelectedFilter, setDateSelectedFilter] = useState('all');
  const [clientFilterQuery, setClientFilterQuery] = useState<string>('');
  const [clientPage, setClientPage] = useState<number>(0);
  const [isPermissionGranted, setIsPermissionGranted] = useState<boolean>(false);
  const [isSubmodulePermissionGranted, setIsSubmodulePermissionGranted] = useState<boolean>(false);
  const [permissions, setPermissions] = useState<any>(Permissions);

  const [autocompleteIds, setAutocompleteIds] = useState<any[]>([]);
  const [isAutocompleteIdDeleted, setIsAutocompleteIdDeleted] = useState<boolean>(false);
  const [isAutocompleteFilterApplied, setIsAutocompleteFilterApplied] = useState<boolean>(false);
  const [isChangeAutocompleteIds, setIsChangeAutocompleteIds] = useState<boolean>(false);
  const [transactionsDownloadParams, setTransactionsDownloadParams] = useState<any>(null);
  const [clientProfileDetails, setClientProfileDetails] = useState<any>(ClientProfileDetails);
  const [clientAccountDetails, setClientAccountDetails] = useState<any>(ClientAccountDetails);
  const [clientProfileInfo, setClientProfileInfo] = useState<any>(null);
  const [pepPayloadData, setPepPayloadData] = useState<any>(null);
  const [fcmToken, setFcmToken] = useState<string>('');
  const [notificationFilterQuery, setNotificationFilterQuery] = useState<string>('');
  const [reportTabValue, setReportTabValue] = useState<string|null>(null);
  const [highlightNotificationRecords, setHighlightNotificationRecords] = useState<boolean>(false);
  const [recordCount, setRecordCount] = useState<number | null>(null);
  const [selectedButtonType, setSelectedButtonType] = useState<string>('');
  const [selectedTabType, setSelectedTabType] = useState<string>('');
  const [transfersStatusFilters, setTransfersStatusFilters] = useState<any>(TransfersStatusFilters);
  const [transactionFilterQuery, setTransactionFilterQuery] = useState<string>('');
  
  return (
    <GlobalContext.Provider
      value={{
        auth, setAuth,
        userRole, setUserRole,
        userInfo, setUserInfo,
        userPermissions, setUserPermissions,
        dateRange, setDateRange,
        searchQuery, setSearchQuery,
        isSearchClosed, setIsSearchClosed,
        filterDates, setFilterDates,
        paymentFilterDates, setPaymentFilterDates,
        isFilterApplied, setIsFilterApplied,
        isFilterCleared, setIsFilterCleared,
        enableSearchField, setEnableSearchField,
        dashboardSelectedFilter, setDashboardSelectedFilter,
        clientStatusFilters, setClientStatusFilters,
        transactionsStatusFilters, setTransactionsStatusFilters,
        finopsTransactionsStatusFilters, setFinopsTransactionsStatusFilters,
        finopsTransfersStatusFilters, setFinopsTransfersStatusFilters,
        giftsStatusFilters, setGiftsStatusFilters,
        legalEntity, setLegalEntity,
        instrumentStatusFilters, setInstrumentStatusFilters,
        isUpdatePage, setIsUpdatePage,
        indeterminateCheck, setIndeterminateCheck,
        travelRuleReportsFilters, setTravelRuleReportsFilters,
        settlementReportsFilters, setSettlementReportsFilters,
        reportBatchIds, setReportBatchIds,
        isReportBatchIdDeleted, setIsReportBatchIdDeleted,
        isReportFilterApplied, setIsReportFilterApplied,
        ordersFilters, setOrdersFilters,
        isRowAdd, setIsRowAdd,
        holidayListFields, setHolidayListFields,
        checkboxList, setCheckboxList,
        selectedAllChecks, setSelectedAllChecks,
        isChangeReportBatchIds, setIsChangeReportBatchIds,
        isWithDrawalTabSelected, setIsWithDrawalTabSelected,
        isWalletTabSelected, setIsWalletTabSelected,
        walletReportDownloadData, setWalletReportDownloadData,
        transactionsDownloadData, setTransactionsDownloadData,
        transactionsHistoryFilters, setTransactionsHistoryFilters,
        defaultFilterDates, setDefaultFilterDates,
        dateSelectedFilter, setDateSelectedFilter,
        isFilterIconDisplay, setIsFilterIconDisplay,
        enableSelectAllCheckbox, setEnableSelectAllCheckbox,
        uncheckedTxnIds, setUncheckedTxnIds,
        checkedTxnIds, setCheckedTxnIds,
        fifoReportBatchId, setFifoReportBatchId,
        instrumentsProfileDetails, setInstrumentsProfileDetails,
        instrumentsCfdProfileDetails, setInstrumentsCfdProfileDetails,
        isSelectAllCheckboxClicked, setIsSelectAllCheckboxClicked,
        mostAppearedTransactionStatus, setMostAppearedTransactionStatus,
        checkedTransactionStatus, setCheckedTransactionStatus,
        clientFilterQuery, setClientFilterQuery,
        clientPage, setClientPage,
        isPermissionGranted, setIsPermissionGranted,
        isSubmodulePermissionGranted, setIsSubmodulePermissionGranted,
        permissions, setPermissions,
        autocompleteIds, setAutocompleteIds,
        isAutocompleteIdDeleted, setIsAutocompleteIdDeleted,
        isAutocompleteFilterApplied, setIsAutocompleteFilterApplied,
        isChangeAutocompleteIds, setIsChangeAutocompleteIds,
        transactionsDownloadParams, setTransactionsDownloadParams,
        clientProfileDetails, setClientProfileDetails,
        clientAccountDetails, setClientAccountDetails,
        clientProfileInfo, setClientProfileInfo,
        pepPayloadData, setPepPayloadData,
        fcmToken, setFcmToken,
        notificationFilterQuery, setNotificationFilterQuery,
        reportTabValue, setReportTabValue,
        highlightNotificationRecords, setHighlightNotificationRecords,
        recordCount, setRecordCount,
        selectedButtonType, setSelectedButtonType,
        selectedTabType, setSelectedTabType,
        transfersStatusFilters, setTransfersStatusFilters,
        transactionFilterQuery, setTransactionFilterQuery
      }}
    >
      { children }
    </GlobalContext.Provider>
  );
};

export default GlobalContextProvider;